import React from 'react'
import Layout from '../../components/layout'
import { graphql, Link } from 'gatsby'
import './prints-page.scss'

export default ({ data }) => {
  return (
    <Layout>
      <div className="page-layout prints-page">
        <div className="container">
          <h1>
            <span style={{ whiteSpace: 'nowrap' }}>Prints</span> / $10
          </h1>
          {/* <h4>{data.allMarkdownRemark.totalCount} Prints</h4> */}
          <div className="prints-page__prints">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id} className="print-container">
                <Link
                  className="print-container__content"
                  to={node.frontmatter.slug}
                >
                  <div>
                    {/* <h3>{node.frontmatter.title}</h3> */}
                    <div
                      class="print-container__image"
                      style={{
                        backgroundImage: `url(${
                          node.frontmatter.image.childImageSharp.fluid.src
                        })`,
                      }}
                    />
                    <img
                      src={node.frontmatter.image.childImageSharp.fluid.src}
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  src
                  srcSet
                  sizes
                }
              }
            }
            # date(formatString: "DD MMMM, YYYY")
          }
        }
      }
    }
  }
`
